import React, { useEffect, useRef, useState } from 'react'
import Tooltip from '../../../components/tooltip';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const ControllerPropery = (props) => {
  const { label, value } = props;
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const classes   = useStyles();

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const isOverflowing =
          textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsOverflowing(isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <Tooltip title={value} interactive disableHoverListener={!isOverflowing}>
      <Typography className={isOverflowing ? classes.textHover: classes.text} ref={textRef}>
        {`${label}: ${value}`}
      </Typography>
    </Tooltip>
  )
}

export default ControllerPropery