import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1.25),
    overflowX   : 'hidden',
    width       : theme.spacing(75),
  },
  dialogTitleContainer: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    padding       : theme.spacing(2),
    paddingLeft   : theme.spacing(3),
  },
  dialogTitle: {
    fontSize    : theme.spacing(2.75),
    fontWeight  : 'bold',
    overflow    : 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace  : 'nowrap',
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    display        : 'flex',
    gap            : theme.spacing(2),
    justifyContent : 'flex-end',
    padding        : theme.spacing(3),
    '& .MuiButton-root': {
      color: colors.PRIMARY
    },
    '& .MuiButton-contained': {
      background: colors.PRIMARY,
      color: colors.WHITE
    }
  },
  dialogButton: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    justifyContent: 'flex-start',
    maxWidth: theme.spacing(32.25),
    minWidth: theme.spacing(7),
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 'fit-content',
  },
  buttonLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  buttonIcon: {
    display: 'flex',
  },
}));