import { Box, Button, Dialog, IconButton, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';

const ControllerModal = (props) => {
  const { children, isOpen, title, onClose, cancelButtonLabel, submitButtonLabel, handleSubmit, buttonStyles, buttonIcon } = props;

  const classes = useStyles();
  
  return (
    <Dialog
      id="deleteModal"
      modal="false"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="row-delete-dialog"
      aria-describedby="row-delete-dialog-table"
    >
      <Paper id="deleteModalBodyContainer" className={classes.paper}>
        <Box>
          <Box className={classes.dialogTitleContainer}>
            <Typography className={classes.dialogTitle}>{ title }</Typography>
            <IconButton onClick={onClose}>
              <Close fontSize='medium'/>
            </IconButton>
          </Box>
        </Box>
        { children }
        <Box 
          id="deleteModalFooterContainer" 
          className={clsx(
            classes.dialogActions, 
            `${buttonStyles ?? ''}`, 
            `${(!submitButtonLabel && !cancelButtonLabel) && 'hidden'}`)
          }>
          <Button
            data-testid="cancel-button"
            id="cancelButton"
            size='medium'
            className={classes.dialogButton}
            onClick={onClose}
            >
            { cancelButtonLabel }
          </Button>
          <Button 
            data-testid="detach-button"
            id="detachButton" 
            size='medium' 
            variant="contained" 
            className={classes.dialogButton} 
            onClick={handleSubmit}
          >
            <>
              {
                buttonIcon && 
                <span className={classes.buttonIcon}>
                  {buttonIcon}
                </span>
              }
              <span className={classes.buttonLabel}>
                {submitButtonLabel}
              </span>
            </>
          </Button>
        </Box>
      </Paper>
    </Dialog>
  )
}

export default ControllerModal;