
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: colors.TOOLTIP_DARK_GRAY,
    borderRadius   : theme.spacing(0.25),
    color          : colors.WHITE,
    fontSize       : theme.spacing(1.75),
    textAlign      : 'center'
  },
}))

export default useStyles;