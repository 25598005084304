import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { CopyAll, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import ActionModal from '../../../components/controller-modal';
import colors from '../../../theme/colors';
import { CONTROLLER_PATH, DEVMAN_PATH, HTTP, HTTPS, WSS } from '../../../utility/constants';
import useStyles from './styles';

const CONTROLLER_URL = window.DEVICE_MANAGEMENT_BASE_PATH.replace(HTTPS, WSS).replace(HTTP, WSS).replace(DEVMAN_PATH, CONTROLLER_PATH);
const PORT = window.DEVICE_MANAGEMENT_PORT;

const ControllerParamItem = ({ label, value, component }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);

  const handleCopyClick = async () => {
    setIsClicked(true);
    await navigator.clipboard.writeText(value);
  };

  const handleMouseLeave = () => {
    setIsClicked(false);
  };

  return (
    <Box className={clsx(classes.container, classes.containerItem)}>
      <Box className={classes.flexForm}>
        <Typography className={classes.label}>{label}</Typography>
        <Box className={classes.content}>
          {component}
          <Tooltip 
            title={isClicked ? t('controller-modal.controller-parameters-copied') : t('controller-modal.controller-parameters-copy')} 
            className={classes.iconTooltip}
          >
            <Box 
              onClick={handleCopyClick} 
              className={clsx({ [classes.iconClicked]: isClicked, [classes.icon]: !isClicked })}
              onMouseLeave={handleMouseLeave}
            >
              <CopyAll />
            </Box> 
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

/**
 * @param {object} props - The `ControllerParametersModal` component accepts the following props:
 * @property {boolean} isOpen - Determines if the modal is visible.
 * @property {function} onClose - Callback function triggered when the modal is closed.
 * @property {string} username - Username for the controller, displayed as a parameter.
 * @property {string} password - Password for the controller, displayed as a parameter.
 * @returns {JSX.Element|null} The `ControllerParametersModal` component renders a modal displaying the controller's connection parameters,
 * including a QR code, URL, port, username, and password. If `isOpen` is false, the component renders `null`.
 */
const ControllerParametersModal = ({ 
  isOpen, 
  onClose, 
  username, 
  password, 
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  
  if (!isOpen) return null;

  const controllerData = t('controller-modal.controller-parameters-qr-params', {
    url: CONTROLLER_URL,
    port: PORT,
    username,
    password,
    interpolation: { escapeValue: false },
  });

  const handleShowPassword = () => {
    setIsPasswordShown(previous => !previous);
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength - 3) + "...";
    }
    return text;
  }

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('controller-modal.controller-parameters-title')}
      handleSubmit={() => {}}
    >
      <Box className={classes.container}>
        <Box className={classes.qRContainer}>
          <Typography className={classes.label}>{t('controller-modal.controller-parameters-qr-code')}</Typography>
          <QRCode
            data-testdata-testid="controllerQrCode"
            value={controllerData}
            fgColor={colors.BLACK}
            size={150}
            className={classes.qr}
          />
        </Box>
      </Box>
      <Box className={classes.paramItemContainer}>
        <ControllerParamItem 
          label={t('controller-modal.controller-parameters-url')} 
          value={CONTROLLER_URL}
          component={<Typography className={classes.value}>{truncateText(CONTROLLER_URL, 50)}</Typography>}
        />
        <ControllerParamItem 
          label={t('controller-modal.controller-parameters-port')} 
          value={PORT}
          component={<Typography className={classes.value}>{PORT}</Typography>}
        />
        <ControllerParamItem 
          label={t('controller-modal.controller-parameters-username')} 
          value={username}
          component={<Typography className={classes.value}>{truncateText(username, 50)}</Typography>}
        />
        <ControllerParamItem 
          label={t('controller-modal.controller-parameters-password')} 
          value={password}
          component={
            <Box className={classes.password}>
              <Typography className={classes.value}>{
                isPasswordShown ?
                  truncateText(password, 50)
                :
                '*'.repeat(truncateText(password, 50).length)
              }</Typography>
              <IconButton
                data-testdata-testid="controllerPasswordVisibilityIcon"
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
              >
                {
                  isPasswordShown ?
                    <VisibilityOutlined />
                  : 
                    <VisibilityOffOutlined />
                }
              </IconButton>
            </Box>
        }/>
      </Box>
    </ActionModal>
  );
};

ControllerParametersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default ControllerParametersModal;