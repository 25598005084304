import { Tooltip as MuiTooltip } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const Tooltip = (props) => {
  const { title, children, disableHoverListener, interactive = true } = props;
  const classes = useStyles();

  return (
    <MuiTooltip
      title={title}
      classes={{ tooltip : classes.tooltip }}
      disableHoverListener={disableHoverListener}
      interactive={interactive}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip;