import { DELETE, GET, PATCH } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getAccessPoint = async (keyword, rowsPerPage, page, orderBy, order, selectedLocationIds) => {
  const response = await request ({
    url     : api.ACCESS_POINTS,
    method  : GET,
    params  : {
      name        : keyword,
      size        : rowsPerPage,
      page        : page,
      sort        : `${orderBy},${order}`,
      locationIds : selectedLocationIds?.join()
    }
  });

  return response.data;
}

export const getAccessPointListCount = async(keyword, selectedLocationIds, rowsPerPage, page) => {
  const response = await request ({
    url     : `${api.ACCESS_POINTS}/count`,
    method  : GET,
    params  : {
      name        : keyword,
      locationIds : selectedLocationIds.join(),
      size        : rowsPerPage,
      page        : page,
    }
  });

  return response.data;
}

export const getAccessPointById = async (id) => {
  const response = await request ({
    url     : `${api.ACCESS_POINTS}/${id}`,
    method  : GET,
  });

  return response.data;
}

export const getAccessPointByProfileId = async (profileId) => {
  const response = await request({
    url     : api.ACCESS_POINTS_BY_PROFILE_ID,
    method  : GET,
    params  : {
      profileIds: profileId
    }
  });

  return response.data;
}

export const deleteAccessPoint = async (id) => {
  await request({
    url     : `${api.ACCESS_POINTS}/${id}`,
    method  : DELETE,
  })
}

export const updateAccessPoint = async (id, requestData) => {
  await request({
    url     : `${api.ACCESS_POINTS}/${id}`,
    method  : PATCH,
    data    : requestData
  });
}
