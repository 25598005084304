import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
    '& .MuiTypography-root': {
      color: colors.DARK_GRAY,
      lineHeight: '20px',
      fontSize: theme.spacing(1.75)
    }
  },
  contentHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing(3),
    '& .MuiListItem-root': {
      display: 'list-item',
      fontSize: theme.spacing(1.75),
      padding: theme.spacing(0.5)
    }
  },
  warning: {
    marginTop: theme.spacing(3),
  },
  button: {
    '& .MuiButton-contained': {
      background: colors.ERROR
    },
    '&. MuiSvgIcon-root': {
      merginRight: theme.spacing(1)
    }
  }
}));