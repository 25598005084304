import { Grid } from '@material-ui/core';
import { TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ControllerModal from '../../../components/controller-modal';
import SelectItems from '../../../components/select-items';
import { createController } from '../../../service/controllersApi';
import { ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, CONTROLLERS, MAX_CHARACTER_LIMIT } from '../../../utility/constants';
import { GetInitialLocationObject } from '../../../utility/location';
import { controllerSchema } from '../../../validation/schema';
import useStyles from './styles';

/**
 * @param {object} props - The `ControllerCreateModal` component accepts the following props:
 * @property {boolean} isOpen - Controls the visibility of the modal. 
 * @property {function} onClose - Callback triggered when the modal is closed.
 * @property {function} handlePermissions - Callback function to manage permissions for location selection.
 * @property {function} showToaster - Function to display a toast message, typically used to show feedback to the user.
 * @returns The `ControllerCreateModal` component renders a modal that allows the user to update the name and location of a controller. 
 * It includes a text field for the name, a location selector, and action buttons for submission or cancellation.
 */
const ControllerCreateModal = ({
  isOpen,
  onClose,
  handlePermissions,
  showToaster,
  showLoading
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formikRef = useRef(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const initialLocationObject = GetInitialLocationObject();

  if (!isOpen) return null;

  const initialValues = {
    name: '',
    location: initialLocationObject || null,
  };

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      
      const response = await createController(values.name, values.location[0].locationId);
      onClose();
      showToaster(t('success'), t('controller-page.createdMessage', { name: values.name }), 'success');
      history.push(`/${CONTROLLERS}/${ACTION_VIEW}/${response.data.controllerId}`);
    } catch (error) {
      const err = error.response;
      if (err.status === 400 && err.data.error === 'username') {
        formikRef.current?.setErrors({
          name: t('controller-page.emptyUsername')
        });
      } else if (err.status === 409) {
        if (err.data.error === 'username') {
          formikRef.current?.setErrors({
            name: t('controller-page.usernameAlreadyExists', { username: values.name.replace(/[^a-zA-Z0-9 ]/g, '') })
          });
        } else {
          formikRef.current?.setErrors({
            name: t('controller-page.nameAlreadyExists')
          });
        }
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ControllerModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('controller-page.addController')}
      cancelButtonLabel={t('cancel')}
      submitButtonLabel={t('create')}
      handleSubmit={() => formikRef.current?.handleSubmit()}
    >
      {showLoading(isLoading)}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={controllerSchema}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({ values, touched, errors, handleChange }) => (
          <Form>
            <Grid container spacing={2} className={classes.form} data-testid="controllerDetailsContainer">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  inputProps={{ maxLength: MAX_CHARACTER_LIMIT.TEXT_FIELD }}
                  label={`${t('controller-page.nameFieldLabel')}*`}
                  name="name"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && t(errors.name)}
                  data-testid="controllerName"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectItems
                  data-testid="controllerLocation"
                  helperText={touched.location && t(errors.location)}
                  isValid={touched.location && Boolean(errors.location)}
                  name="Locations"
                  onChange={(value) => handleChange({ target: { name: 'location', value } })}
                  selectedItems={values.location}
                  showToaster={showToaster}
                  single
                  required
                  handlePermissions={handlePermissions}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ControllerModal>
  );
};

ControllerCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handlePermissions: PropTypes.func.isRequired,
  showToaster: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
};

export default ControllerCreateModal;